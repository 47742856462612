@media (max-width: $screen-lg) {
    // lg-md view

    .desktop-only {
        display: none !important;
    }

    .mobile-only {
        display: initial !important;
    }

    .wrapper {
    	min-width: 590px;
    	max-width: 100%;
    }

    .wrapper-inside {
        width: 90.625%;
    }

    /* Header
    -----------------------------------------------------------------------------*/
    .header {

    }

    /* Middle
    -----------------------------------------------------------------------------*/
    .content {

    }

    /* Footer
    -----------------------------------------------------------------------------*/
    .footer {

    }
}

@media (max-width: $screen-md) {
    // md-view

}
