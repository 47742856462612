form {

    .action {
        display: inline-block;
        width: 100%;
        margin: 10px 0 0;
        .agree-col {
            padding-top: 15px;
            width: calc(100% - 200px);
            float: left;
            margin-bottom: 20px;
            .checkcontainer {
                .name {
                    a {
                        color: $brand-secondary;
                        text-decoration: none;
                        transition: all 300ms;
                        &:hover {
                            cursor: $brand-secondary-accent;
                            transition: all 300ms;
                        }
                    }
                }
            }
        }
        .submit {
            width: 200px;
            float: left;
            .make-submit {
                border: none;
                outline: none;
                padding: 0;
                margin: 0;
                font-style: italic;
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: 0.008em;
                text-transform: uppercase;
                background: none;
                text-decoration: underline;
                text-decoration-color: transparent;
                color: $brand-primary;
                transition: all 300ms;
                &:hover {
                    color: $brand-primary-accent;
                    text-decoration-color: currentColor;
                    transition: all 300ms;
                }
            }
        }
    }

    .error-note {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        position: relative;
        padding-top: 10px;
        &:before {
            content: "";
            display: inline-block;
            width: 88px;
            height: 0;
            border-top: 2px solid #EB5757;
            position: absolute;
            left: 0;
            top: 0;
        }
        .note {
            margin-bottom: 10px;
            &.error {
                color: red;
            }
            &.warning {
                color: #FFD300;
            }
        }

    }

    .form-group {
        display: inline-block;
        width: calc(100% + 40px);
        margin-left: -20px;
    	.form-col {
            display: inline-block;
            float: left;
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            &.col-50 {
                width: 50%;
            }
            &.col-33 {
                width: calc(100%/3);
            }

    	}
    }

    .form-field {
        display: flex;
        width: 100%;
        margin-bottom: 25px;
        label.select, label.input {
            display: inline-block;
            width: 100%;
            position: relative;
            .name {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 130%;
                margin-bottom: 6px;
                mark {
                    color: $brand-primary;
                    background: none;
                    font-weight: bold;
                }
            }
            .note {
                display: inline-block;
                width: 100%;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 130%;
                color: $gray;
                position: absolute;
                top: calc(100% + 5px);
                left: 0;
            }
        }

        label.select {
            .selectric-selectric-select {
                .selectric {
                    background: #FFFFFF;
                    border: 1px solid #CED2D5;
                    box-sizing: border-box;
                    border-radius: 1px;
                    height: 40px;
                    .label {
                        font-family: $main_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 130%;
                        color: $gray-dark;
                        display: block;
                        margin: 13px 40px 0 13px;
                        height: 100%;
                        width: calc(100% - 60px);
                    }
                    .button {
                        height: 40px;
                        width: 40px;
                        border-left: 1px solid #CED2D5;
                        box-sizing: border-box;
                        transition: all 300ms;
                        &:hover {
                            background-color: $gray-lighter;
                            transition: all 300ms;
                        }
                        &:after {
                            border: none;
                            background-image: url(../img/select-shevron.svg);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: auto;
                            background-clip: border-box;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .selectric-items {
                    background: #FFFFFF;
                    border: 1px solid #CED2D5;
                    .selectric-scroll {
                        ul {
                            position: relative;
                            width: 100%;
                            max-height: 282px;
                            overflow: hidden;
                            li {
                                display: flex;
                                align-items: center;
                                font-family: $main_font;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 130%;
                                min-height: 40px;
                                padding: 10px 10px 10px 15px;
                                box-sizing: border-box;
                                &:hover {
                                    background: $gray-lighter;
                                }
                                &.selected  {
                                    background: $brand-primary;
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
        label.input {
            &.addon {
                input {
                    padding: 0px 30px 0 14px;
                }
            }

            &.error {
                input, textarea {
                    border-color: red;
                    color: red;
                    @include placeholder {
                        color: red;
                    }
                    &:focus {
                        border-color: red;
                    }
                }
            }
            &.success {
                input, textarea {
                    border-color: green;
                    @include placeholder {

                    }
                    &:focus {
                        border-color: green;
                    }
                }
            }
            &.warning {
                input, textarea {
                    border-color: #FFD300;
                    @include placeholder {

                    }
                    &:focus {
                        border-color: yellow;
                    }
                }
            }

            input, textarea {
                display: inline-flex;
                float: left;
                width: 100%;
                height: 44px;
                align-items: center;
                padding: 0 0 0 0;
                font-family: $main_font;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                background: white;
                border: 0.5px solid $brand-primary;
                border-left: none;
                border-right: none;
                border-top: none;
                box-sizing: border-box;
                border-radius: 1px;
                transition: all 300ms;
                color: $gray-base;
                &:focus {
                    outline: none;
                    border-color: $brand-primary-accent;
                    transition: all 300ms;
                    @include placeholder {
                        color: $gray-lighter;
                        transition: all 300ms;
                    }
                }
                &:disabled {
                    background: #F6F7FA;
                    color: $gray;
                    &:focus {
                        background: #F6F7FA;
                        color: $gray;
                        border-color: #CED2D5;
                    }
                }
                @include placeholder {
                    font-family: $main_font;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 140%;
                    letter-spacing: 0.008em;
                    text-transform: uppercase;
                    color: $gray-base;
                    transition: all 300ms;
                }
            }
            textarea {
                padding: 11px 10px 0 14px;
                height: 300px;
            }

            .addon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 8px;
                button {
                    padding: 0;
                    margin: 0;
                    background: none;
                    outline: none;
                    border: none;
                    color: $gray-base;
                    transition: all 300ms;
                    &:hover {
                        color: $brand-secondary;
                        transition: all 300ms;
                    }
                }
                a {
                    display: inline-block;
                    color: $gray-base;
                    text-decoration: none;
                    transition: all 300ms;
                    .icon {
                        svg {

                        }
                    }
                    &:hover {
                        color: $brand-secondary;
                        transition: all 300ms;
                    }
                }
            }
        }
        label.file-upload {
            display: flex;
            align-items: center;
            input {
                display: none;
            }
            .btn {
                display: inline-block;
                float: left;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 130%;
                padding: 20px 42px 14px;
                &:hover {
                    .icon {
                        color: white;
                        transition: all 300ms;
                    }
                    .text {
                        color: white;
                        transition: all 300ms;
                    }
                }
                .icon {
                    display: inline-block;
                    float: left;
                    color: $brand-primary;
                    margin-right: 10px;
                    transition: all 300ms;
                }
                .text {
                    display: inline-block;
                    float: left;
                    color: white;
                    transition: all 300ms;
                }
            }
            .filename {
                display: inline-block;
                float: left;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 130%;
                margin-left: 20px;
                color: white;
            }
        }
    }
}

// @import "../media/tablet/includes/forms";
// @import "../media/mobile/includes/forms";
