@media (max-width: $screen-sm) {
    // sm-view
    $menu-width: 236px;
    .mobile-menu {
    	.mobile-menu-holder {
            .menu-body {
                width: $menu-width;
                &.animated {
    				transform: translateX(-$menu-width);
    			}
                >.heading {

                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
