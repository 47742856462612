/* Customize the label (the container) */
.checkcontainer {
    width: 100%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.radio {
        .checkmark {
            border-radius: 50%;
        }
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
            background-color: white;
            &::after {
                opacity: 1;
                transition: all 300ms ease;
            }
        }
    }
    &:hover input ~ .checkmark {
        background-color: $bg-secondary;
    }
    .checkmark {
        display: inline-block;
        float: left;
        left: 0px;
        top: 0px;
        height: 17px;
        width: 17px;
        box-sizing: border-box;
        background-color: white;
        border: none;
        border-radius: 5px;
        border: 1px solid gray;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            display: inline-block;
            opacity: 0;
            left: 50%;
            top: 50%;
            width: 2px;
            height: 6px;
            border: solid $brand-primary;
            border-width: 0 2px 2px 0;
            -webkit-transform: translate(-50%, -50%) rotate(45deg);
            -ms-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
            transition: all 300ms ease;
        }
    }
    .name {
        display: inline-block;
        float: left;
        width: calc(100% - 17px - 5px);
        margin-left: 5px;
    }
}
