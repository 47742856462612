&.small {
    .footer-holder { 
        .footer-main { 
            .footer-main-holder { 
                width: 980px;
                float: right;
                margin: 0 10px 0 0;
                .logo {
                    width: 100%;
                }

                .right {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin: 30px 0 0;
                    box-sizing: border-box;
                    padding: 0 0 0 73px;
                    .links { 
                        .footer-menu { 
                            nav { 
                                ul {
                                    column-count: 2;
                                    column-gap: 22px;
                                }
                                
                            }
                        }

                        .other-links { 
                            margin: -1px 0 0 31px;
                            .social { 
                                display: none;
                            }
                        }
                    }

                    .back-to-top { 
                        padding: 0;
                        margin-left: 72px;
                        margin-top: -3px;
                    }
                }
            }
        }
    }
}

.footer-holder { 
    display: inline-block;
    width: 100%;
	.footer-main { 
        display: inline-block;
        width: 100%;
		.footer-main-holder { 
            display: inline-block;
            width: 100%;
            border-top: none;
            padding: 0;
            .logo { 
                float: left;
                a { 

                    svg { 

                        path { 

                        }
                    }
                }
            }

            .right { 
                float: right;
                .links { 
                    float: left;
                    .footer-menu { 
                        float: left;
                        margin: -1px 0 0;
                        nav { 

                            ul { 
                                list-style: none;
                                padding: 0;
                                li { 

                                    a {
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 12px;
                                        line-height: 140%;
                                        letter-spacing: 0.8px;
                                        text-transform: uppercase;
                                        &.current { 
                                            font-style: italic;
                                            font-weight: 700;
                                            text-decoration-color: currentColor;
                                            &:hover {
                                                color: $gray-base;
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }

                    .other-links { 
                        float: left;
                        margin: -1px 0 0 43px;
                        .link { 
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 140%;
                            letter-spacing: 0.8px;
                            a { 

                            }
                        }

                        .social { 
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 140%;
                            letter-spacing: 0.8px;
                            text-transform: uppercase;
                            margin: 17px 0 0;
                            a { 

                            }
                        }
                    }
                }

                .back-to-top { 
                    float: left;
                    padding: 31px 0 0;
                    margin-left: 67px;
                    a { 

                        svg { 

                            path { 

                            }
                        }
                    }
                }
            }
        }
	}
}