@media (max-width: $screen-sm) {
    // sm-view
    .wrapper {
    	min-width: 100%;
    	max-width: 100%;
    }

    .wrapper-inside {
        width: 90.625%;
    }

    /* Header
    -----------------------------------------------------------------------------*/
    .header {

    }

    /* Middle
    -----------------------------------------------------------------------------*/
    .content {

    }

    /* Footer
    -----------------------------------------------------------------------------*/
    .footer {
        min-width: 100%;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
