.header-holder { 

	.header-main { 

		.header-main-holder { 
            display: inline-block;
            width: 100%;
            padding: 40px 0 0;
            .mobile-menu { 

                .mobile-menu-open { 

                }
            }

            .logo { 
                float: left;
                a { 
                    display: inline-block;
                    svg { 
                    }
                }
            }

            .menu { 
                float: right;
                background: white;
                margin: 0 4px;   
                position: relative;
                z-index: 2;         
                nav { 
                    
                    ul { 
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        li { 
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 140%;
                            letter-spacing: 0.8px;
                            text-transform: uppercase;
                            float: left;
                            margin-left: 28px;
                            &:first-of-type {
                                margin-left: 0;
                            }
                            &.current { 
                                font-weight: 700;
                                a { 
                                    text-decoration-line: underline;
                                    text-decoration-color: currentColor;
                                    font-style: italic;
                                    color: $gray-base;
                                    cursor: default;
                                    &:hover {
                                        color: $gray-base;
                                    }
                                }
                            }

                            a { 

                            }
                        }
                    }
                }
            }
        }
	}
}