.general.form-block {
    padding: 0;
    margin: 0;
    width: 980px;
    .form-block-holder {
        padding: 0;
        form { 
        
            .form-group { 
    
                .form-col { 
    
                    .form-field { 
                        margin: 0;
                        label.input { 
    
                            .name { 
    
                            }
    
                            input { 
                                font-size: 14px;
                                @include placeholder {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}