@import "../mixins";

$menu-width: 471px;

/*Mobile menu
-----------------------------------------------------------------------------*/
.mobile-menu {
	.mobile-menu-holder {
		.menu-background {
			position: fixed;
			z-index: 1201;
			top: 0;
			left: 0;
			background: $gray-base;
			opacity: 0.64;
			width: 100%;
			height: 100%;
			@include transition-easy-in-out(250ms);
			&.animated {
				opacity: 0;
				@include transition-easy-in-out(250ms);
			}
			&.closed {
				display: none;
			}
		}
		.menu-body {
			position: fixed;
			z-index: 1202;
			top: 0;
			left: 0;
			display: inline-block;
			float: left;
			width: $menu-width;
			height: 100%;
			box-sizing: border-box;
			padding: 0;
			background: white;
			opacity: 1;
			transform: translateX(0);
			overflow-y: auto;
			@include transition-easy-in-out(350ms);
			&.animated {
				transform: translateX(-$menu-width);
				@include transition-easy-in-out(350ms);
			}
			&.closed {
				display: none;
			}
            &.mobile-offset {

            }
			>.heading {
				background: white;
				display: inline-block;
				width: 100%;
				padding-top: 0;
			    padding-left: 0;
			    padding-bottom: 0;
			    margin-bottom: 0;
				box-sizing: border-box;
				background: $gray-lighter;
				a.close {
	                display: inline-block;
                    float: left;
	                color: $brand-primary;
					width: 43px;
					height: 43px;
					border: 2px solid $brand-primary;
					border-radius: 4px;
					line-height: 40px;
					text-align: center;
	                svg {
	                    width: 21px;
	                    height: 17px;
	                    vertical-align: middle;
	                    fill: currentColor;
						transform: translate(-1px, -1px);
	                    @include transition-easy-in-out(350ms);
	                }
	                &:hover, &:focus {
						background: $brand-primary;
						color: white;
	                    svg {
	                        @include transition-easy-in-out(350ms);
	                        transform: rotate(180deg);
							fill: currentColor;
	                    }
	                }
	            }
			}
			.main-body {
				display: block;
				overflow-y: hidden;
			    overflow-x: hidden;
			    padding-top: 0;
				height: auto;
			}
            /*naib body contents*/
		}
	}
}

@import "../media/mobile/includes/mobile_menu";
@import "../media/tablet/includes/mobile_menu";
